import { PUB_ID } from '../constants.mjs';


export default function ConfigStore(configKey, configDefaults) {
    const CUSTOM_EVENT_NAME = `${configKey}_EVENT`;
    const localStorageKey = `${window.localStorage.getItem(PUB_ID)}.${configKey}`;

    const config = JSON.parse(window.localStorage.getItem(localStorageKey));
    if (!config) {
        // If localStorage is not set, initialize with defaults
        const defaultValue = JSON.stringify(configDefaults);
        window.localStorage.setItem(localStorageKey, defaultValue);
    }

    this.getSnapshotFn = (selector = (config) => config) => {
        return () => {
            const config = JSON.parse(window.localStorage.getItem(localStorageKey));
            const slice = selector(config);
            return JSON.stringify(slice);
        }
    };

    this.set = (updater) => {
        const configString = window.localStorage.getItem(localStorageKey);
        const config = JSON.parse(configString);
        updater(config);
        const newConfig = JSON.stringify(config);
        if (newConfig === configString) {
            return;
        }
        window.localStorage.setItem(localStorageKey, newConfig);
        // Trigger update
        window.dispatchEvent(new Event(CUSTOM_EVENT_NAME));
    };

    this.subscribe = (callback) => {
        window.addEventListener(CUSTOM_EVENT_NAME, callback);
        return () => {
            window.removeEventListener(CUSTOM_EVENT_NAME, callback);
        };
    };
};
