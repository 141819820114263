import { useEffect, useState } from 'react';
import {
  ActionIcon,
  Button,
  Combobox,
  Group,
  Input,
  InputBase,
  Loader,
  Popover,
  Select,
  Stack,
  Tooltip,
  useCombobox
} from '@mantine/core';
import { getHotkeyHandler, useClickOutside, useDisclosure } from '@mantine/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import { IconCirclePlus, IconCopy, IconTemplate, IconTrash, IconX } from '@tabler/icons-react';
import { useQueryClient } from '@tanstack/react-query';

import classes from  './TemplateCombobox.module.css';
import { rapini } from '../api/client.mjs';
import { duplicateTemplateConfig, isBetweenClasses, notify } from '../helpers';
import { useDialog } from '../providers/DialogProvider';


export default function TemplateCombobox({ collection: _collection }) {
  const navigate = useNavigate();
  const { collectionId, templateId: _templateId = -1 } = useParams();
  const selectedTemplateId = parseInt(_templateId, 10);

  const queryClient = useQueryClient();
  const { data: collection = _collection } = rapini.queries.useGetCollectionWithTemplates(collectionId);
  const { data: collections } = rapini.queries.useListCollections();
  const [refreshing, setRefreshing] = useState(false);
  const [opened, { close: closeDropdown, toggle: toggleDropdown }] = useDisclosure(false);

  const initialPopoverState = collection.templates.reduce((carry, { template_id: id }) => {
    carry[id] = {
      copying: false,
      opened: false,
      selectedCollectionId: `${collection.id}`,
    };
    return carry;
  }, {});
  const [popoverState, _setPopoverState] = useState(initialPopoverState);

  useEffect(() => {
    _setPopoverState(initialPopoverState);
  }, [collection]);

  const [modalOpened, { open: modalOpen, close: modalClose, stopLoading: modalStopLoading }] = useDialog({
    confirmText: "Delete Template",
    text: "The template with all its layers will be deleted.",
    title: "Irreversible Action",
  });

  const comboRef = useClickOutside(() => {
    if (modalOpened) {
      return;
    }
    closeDropdown();
  });
  const combobox = useCombobox({ opened });

  function refreshCollectionData() {
    setRefreshing(true);
    return queryClient
      .invalidateQueries({ queryKey: rapini.queryKeys.getCollectionWithTemplates(collectionId) })
      .then(() => {
      combobox.updateSelectedOptionIndex();
    }).catch(err => {
      notify({ message: `Collection data could not be refreshed. ${err?.message || ''}` });
    }).finally(() => {
      setRefreshing(false);
    });
  }

  const createTemplate = rapini.mutations.useCreateTemplate({
    onSuccess: ({ id }) => {
      if (!id) {
        return notify({ message: 'A new template could not be created.' });
      }
      refreshCollectionData();
    },
  });

  function deleteTemplate(templateId) {
    rapini.requests.deleteTemplate(templateId)
      .then(({ success }) => {
        if (!success) {
          throw new Error('Server response: Unsuccessful');
        }
        if (selectedTemplateId === templateId) {
          navigate(`/collection/${collection.id}/edit`);
        }
        return refreshCollectionData();
      }).then(() => {
        modalClose();
      }).catch(err => {
        modalStopLoading();
        notify({ message: `Failed to delete the template. ${err?.message || ''}` });
      });
  }

  function handleDeleteTemplateBtn(templateId) {
    modalOpen(deleteTemplate, [templateId])();
  }

  function handleCopyTemplateBtn(templateId, collectionId) {
    setPopoverState(templateId, 'copying', true);
    rapini.requests.duplicateTemplate({ collection_id: collectionId }, templateId)
      .then(({ success, template }) => {
        if (!success) {
          throw new Error('Server response: Unsuccessful');
        }

        const copiedWithin = `${collection.id}` === `${template.collection_id}`;
        duplicateTemplateConfig(templateId, template.id);
        notify({
          color: '#fff',
          message: copiedWithin ?
            `The template duplicated as "Template #${template.id}".` :
            `The template copied to "${collections.find(c => c.id === template.collection_id)?.name}" as "Template #${template.id}".`,
          title: `Template ${copiedWithin ? 'duplicated' : 'copied'}`,
        });

        if (copiedWithin) {
          return refreshCollectionData();
        }
      }).then(() => {
        setPopoverState(templateId, ['copying', 'opened']);
      }).catch(err => {
        notify({ message: `Failed to copy the template. ${err?.message || ''}` });
        setPopoverState(templateId, 'copying');
      });
  }

  function setPopoverState(templateId, keys, values) {
    keys = Array.isArray(keys) ? keys : [keys];
    values = Array.isArray(values) ? values : [values];
    const stateUpdate = Object.fromEntries(keys.map((key, idx) => [key, values[idx] || false]));
    const currentState = popoverState[templateId] || {};
    _setPopoverState({ ...popoverState, ...{ [templateId]: {...currentState, ...stateUpdate} } });
  }

  function openCopyPopover(templateId) {
    setPopoverState(templateId, 'opened', true);
  }

  function closeCopyPopover(templateId) {
    setPopoverState(templateId, 'opened', false);
  }

  function handleOptionSelect(ev) {
    const btnTags = new Set(['svg', 'path']);
    const isExcluded = ['ActionIcon', 'Button', 'Select']
      .some(cl => ev.target.className.includes?.(cl));

    if (
      isExcluded ||
      btnTags.has(ev.target.tagName.toLocaleLowerCase()) ||
      isBetweenClasses(ev.target, 'Popover', 'Combobox')
    ) {
      return;
    }

    const templateId = parseInt(ev.currentTarget.getAttribute('value'), 10);
    navigate(`/collection/${collection.id}/template/${templateId}/edit`);
    closeDropdown();
  }

  const options = collection.templates.map(({ template_id }) => (
    <Combobox.Option value={template_id} key={template_id} onClick={handleOptionSelect}>
      <Group justify="space-between" wrap="nowrap">
        <span>Template #{template_id}</span>
        <Group gap={6} wrap="nowrap">
          <Popover
            classNames={{ dropdown: classes.popoverDropdown }}
            onChange={() => closeCopyPopover(template_id)}
            opened={popoverState?.[template_id]?.['opened']}
            position="top"
            trapFocus
            withArrow
            withinPortal={false}
          >
            <Popover.Target>
              <Tooltip label="Copy template" disabled={popoverState?.[template_id]?.['opened']}>
                <ActionIcon
                  aria-label="Copy template"
                  disabled={popoverState?.[template_id]?.['opened']}
                  onClick={() => openCopyPopover(template_id)}
                  variant="white"
                >
                  <IconCopy size={12} />
                </ActionIcon>
              </Tooltip>
            </Popover.Target>
            <Popover.Dropdown>
              <Stack>
                <Select
                  classNames={{ option: classes.option }}
                  comboboxProps={{ withinPortal: false }}
                  data={collections?.map(c => ({ label: `${c.name} #${c.id}`, value: `${c.id}` })) || []}
                  defaultValue={`${collection.id}`}
                  description="The template will be copied into this collection"
                  disabled={popoverState?.[template_id]?.['copying']}
                  label="Collection to copy into"
                  onChange={(value) => setPopoverState(template_id, 'selectedCollectionId', value)}
                  value={popoverState?.[template_id]?.['selectedCollectionId']}
                />
                <Button.Group>
                  <Button
                    leftSection={<IconCopy size={14} />}
                    loading={popoverState?.[template_id]?.['copying']}
                    onClick={() => handleCopyTemplateBtn(template_id, popoverState?.[template_id]?.['selectedCollectionId'])}
                    size="xs"
                    variant="variant"
                  >
                    Copy Template
                  </Button>
                  <Button
                    leftSection={<IconX size={14} />}
                    loading={popoverState?.[template_id]?.['copying']}
                    onClick={() => closeCopyPopover(template_id)}
                    size="xs"
                    variant="light"
                  >
                    Cancel
                  </Button>
                </Button.Group>
              </Stack>
            </Popover.Dropdown>
          </Popover>
          <Tooltip label="Delete template">
            <ActionIcon
              onClick={() => handleDeleteTemplateBtn(template_id)}
              variant="white"
              aria-label="Delete template"
            >
              <IconTrash size={12} />
            </ActionIcon>
          </Tooltip>
        </Group>
      </Group>
    </Combobox.Option>
  ));

  return (<>
    <Combobox
      miw={210}
      onKeyDown={getHotkeyHandler([
        ['Escape', closeDropdown],
      ])}
      onOptionSubmit={(value) => {
        if (value === '$create') {
          return createTemplate.mutate({ collection_id: collection.id });
        }
      }}
      store={combobox}
    >
      <Combobox.Target ref={comboRef}>
        <InputBase
          component="button"
          description="A template holds its own set of layers"
          label="Template Name"
          leftSection={<IconTemplate size={14} />}
          onClick={toggleDropdown}
          pointer
          rightSection={refreshing ? <Loader color='#fff' size={14} /> : <Combobox.Chevron />}
          rightSectionPointerEvents="none"
          styles={{ description: { whiteSpace: 'nowrap' }}}
          type="button"
        >
          {selectedTemplateId === -1 ?
            <Input.Placeholder>{options.length < 1 ? 'Create' : 'Select'} a template</Input.Placeholder> :
            `Template #${selectedTemplateId}`
          }
        </InputBase>
      </Combobox.Target>

      <Combobox.Dropdown ref={comboRef}>
        <Combobox.Options>
          {options.length < 1 ? <Combobox.Empty>No templates</Combobox.Empty> : options}
          <Combobox.Option
            value="$create"
            disabled={createTemplate.isPending}
            p={0} mt={2} opacity={createTemplate.isPending ? 0.7 : 1}
          >
            <Button
              leftSection={createTemplate.isPending ? <Loader color='#333' size={14} /> : <IconCirclePlus size={14}/>}
              w="100%"
              variant="white"
              size="xs"
              styles={{ section: { marginTop: '-4px' } }}
            >
              Create new template
            </Button>
          </Combobox.Option>
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  </>);
}
