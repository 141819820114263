import { useQueryClient } from '@tanstack/react-query';

import { rapini } from '../api/client.mjs';


export default function useSetTemplateLayerQueryData(layers, templateId) {
    const queryClient = useQueryClient();

    return function setTemplateLayerQueryData(updatedLayer) {
        const updatedData = layers.slice(); // a shallow copy seems to suffice here
        updatedData[updatedData.findIndex(layer => layer.id === updatedLayer.id)] = updatedLayer;
        queryClient.setQueryData(
            rapini.queryKeys.getTemplateLayers(templateId),
            updatedData
        );
    }
}
