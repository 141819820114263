import { createTheme } from '@mantine/core';


const themeColors = [
    "#edf4fc",
    "#d9e5f4",
    "#aec8eb",
    "#81abe3",
    "#5d92dc",
    "#4782d8",
    "#3b7ad7",
    "#2e68c0",
    "#255cab",
    "#164f98"
];

const cssVariablesResolver = (theme) => ({
    variables: {
        '--mantine-color-body-alt': theme.other.heroDark,
    },
});

const theme = createTheme({
    colors: {
        dark: themeColors,
    },
    other: {
        heroDark: '#06172c',
    },
});


export {
    cssVariablesResolver,
    theme,
};
