import { useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { Container, Flex, ScrollArea, Text } from '@mantine/core';
import { useListState } from '@mantine/hooks';
import { useParams } from 'react-router-dom';

import classes from './LayersOverview.module.css'
import { rapini } from '../api/client.mjs';
import useDeleteLayer from '../hooks/useDeleteLayer.mjs';
import useSetLayerOrder from '../hooks/useSetLayerOrder.mjs';
import useSetTemplateLayerQueryData from '../hooks/useSetTemplateLayerQueryData.mjs';
import LayersOverviewItem from './LayersOverviewItem';
import LoadingOverlay from './LoadingOverlay';


export default function LayersOverview() {
  const { templateId: selectedTemplateId } = useParams();
  const { data: layerData = [], isLoading, isError } = rapini.queries.useGetTemplateLayers(selectedTemplateId);
  const setTemplateLayerQueryData = useSetTemplateLayerQueryData(layerData, selectedTemplateId);
  const layerOrder = useSetLayerOrder(selectedTemplateId, layerData);
  const [layerListState, layerListHandlers] = useListState(layerData);
  const handleDeleteLayerBtn = useDeleteLayer(layerOrder);

  useEffect(() => {
    layerListHandlers.setState(layerData);
  }, [layerData]);

  function handleOnDragEnd({ destination, source }) {
    const destIndex = destination?.index || 0;
    if (destIndex === source.index) {
      return;
    }
    layerListHandlers.reorder({ from: source.index, to: destIndex });
    layerOrder.move(layerListState[source.index].id, destIndex)
      .catch(() => {
        // Revert in case of an error
        layerListHandlers.reorder({ from: destIndex, to: source.index });
      });
  }

  return (
    <Container className={classes.container}>
      <LoadingOverlay visible={layerOrder.isPending}/>
      <Text ta="center" my="0.5rem" size="sm">Layers Overview</Text>
      {!isLoading && !isError && (layerListState.length > 0 ?
        <ScrollArea
          classNames={{ scrollbar: 'g-scrollbar' }}
          h="inherit"
          offsetScrollbars
          scrollbars="y"
          type="auto"
        >
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="layers-overview-dnd" direction="vertical">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {layerListState.map((layer, index) =>
                    <Draggable key={`${layer.id}`} index={index} draggableId={`${layer.id}`}>
                      {(provided) => (
                        <LayersOverviewItem
                          handleDeleteLayerBtn={handleDeleteLayerBtn}
                          layer={layer}
                          provided={provided}
                          setTemplateLayerQueryData={setTemplateLayerQueryData}
                        />
                      )}
                    </Draggable>
                  )}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </ScrollArea>
        :
        <Flex justify="center" align="center" h="inherit">
          <Text size="sm" color="var(--mantine-color-dimmed)" bd="1px solid #243a59" p="1rem 1.5rem" mt={-20}>No Layers</Text>
        </Flex>
      )}
    </Container>
  );
}
