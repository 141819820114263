import Axios from 'axios';

import { initialize, queryKeys } from '../../../backend/build/openapi';
import { API_KEY } from '../constants.mjs';
import { notify } from '../helpers';


export const axios = Axios.create({
    baseURL: '/api/v1', // todo: take this and apiKey (as constant) from backend
    headers: {
        Authorization: `Bearer ${window.localStorage.getItem(API_KEY)}`,
    },
});

const onError = (error) => {
    notify({ message: error.message });
    console.error(error); // note: sentry?
};

// Global config for onError hook
const config = {
    mutations: Object.fromEntries(
        Object.keys(initialize().mutations).map(key => [key, (queryClient) => ({ onError })])
    ),
};

export const rapini = initialize(axios, config);

rapini.queryKeys = queryKeys;
