import { createContext, useEffect, useState } from 'react';

import api from '../../../backend/public/js/api.mjs';
import { API_KEY, PUB_ID } from '../constants.mjs';


const _4001 = 4001; // User rejected the request
export const AuthContext = createContext(null);

export function AuthProvider({ children }) {
  const [loggedIn, setLoggedIn] = useState(false);
  const provider = getProvider();
  // todo: handle no-provider case

  useEffect(() => {
    async function asyncEffect() {
      const result = await isLoggedIn();
      if (!ignore) {
        setLoggedIn(result);
      }
    }

    let ignore = false;
    asyncEffect();
    return () => {
      ignore = true;
    };
  });

  return (
    <AuthContext.Provider value={{loggedIn, provider}}>
      {children}
    </AuthContext.Provider>
  );
}

export async function signInWithWallet(provider, redirectTo) {
  try {
    const data = await provider.signIn({});
    const payload = {
      publicKey: data.address.toBase58(),
      signature: data.signature.toString('hex'),
      signedMessage: data.signedMessage.toString('hex'),
    };

    const result = await api.postJson('/login/wallet', payload);
    if (result?.success) {
      window.localStorage.setItem(API_KEY, result.defaultApiKey);
      window.localStorage.setItem(PUB_ID, payload.publicKey.slice(-5));
      window.location.href = redirectTo || result.redirectTo;
    }
    // todo: fail case
  } catch (err) {
    // "Trusted" attempt also returns 4001 when failed
    if (err.code !== _4001) {
      console.log(err);
    }
  }
}

export async function logOut(redirectTo) {
  const reply = await api.get('/logout');
  if (reply?.success) {
    window.localStorage.removeItem(API_KEY);
    window.localStorage.removeItem(PUB_ID);
    window.document.location = redirectTo || '/';
  }
}

function getProvider() {
  if ('phantom' in window) {
    const provider = window.phantom?.solana;

    if (provider?.isPhantom) {
      return provider;
    }
  }
  return false;
}

async function isLoggedIn() {
  const reply = await api.get('/is-logged-in');
  return reply?.isLoggedIn;
}
