import "@mantine/core/styles.css";
import '@mantine/carousel/styles.css';
import '@mantine/dropzone/styles.css';
import '@mantine/notifications/styles.css';
import { MantineProvider } from "@mantine/core";
import { Notifications } from '@mantine/notifications';
import { HelmetProvider } from 'react-helmet-async';
import { createBrowserRouter, Outlet, RouterProvider } from 'react-router-dom';

import "./App.css";
import collectionEditLoader from './loaders/collectionEditLoader.mjs';
import collectionLoader from './loaders/collectionLoader.mjs';
import { cssVariablesResolver, theme } from "./theme";
import { AuthProvider }  from "./providers/AuthProvider";
import { DialogProvider } from './providers/DialogProvider';
import QueryClientProvider  from "./providers/QueryClientProvider";
import AppLayout from './components/AppLayout';
import ProtectedView from './components/ProtectedView';
import Collection from "./pages/Collection";
import CollectionEdit from "./pages/CollectionEdit";
import Collections from "./pages/Collections";
import Home from "./pages/Home";
import NotFound from './pages/404.jsx';


const router = createBrowserRouter([
  { path: '/', element: <AppLayout><Outlet/></AppLayout>, errorElement: <NotFound/>, children: [
    { index: true, element: <Home/> },
    { path: 'collections', element: <ProtectedView><Collections/></ProtectedView> },
    { path: 'collection/:collectionId', element: <ProtectedView><Collection/></ProtectedView>, loader: collectionLoader },
    { path: 'collection/:collectionId/edit', element: <ProtectedView><CollectionEdit/></ProtectedView>, loader: collectionEditLoader },
    { path: 'collection/:collectionId/template/:templateId/edit', element: <ProtectedView><CollectionEdit/></ProtectedView>, loader: collectionEditLoader },
  ]},
]);

export default function App() {
  // Keep zIndex of <Notifications> largest to make them always "visible"
  return <MantineProvider theme={theme} cssVariablesResolver={cssVariablesResolver} forceColorScheme="dark">
    <Notifications zIndex={1100} />
    <AuthProvider>
      <QueryClientProvider>
        <HelmetProvider>
          <DialogProvider>
            <RouterProvider router={router} />
          </DialogProvider>
        </HelmetProvider>
      </QueryClientProvider>
    </AuthProvider>
  </MantineProvider>;
}
