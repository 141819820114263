import { useEffect, useRef, useState } from 'react';
import { ActionIcon, FileButton, Group, Image, NumberInput, Paper, Popover, Slider, Stack, Text, TextInput, ThemeIcon, Tooltip } from '@mantine/core';
import { IMAGE_MIME_TYPE } from '@mantine/dropzone';
import { useDebouncedCallback } from '@mantine/hooks';
import { useParams } from 'react-router-dom';
import { IconAlertTriangle, IconGripVertical, IconPencil, IconPhotoUp, IconTrash } from '@tabler/icons-react';

import classes from './Trait.module.css';
import { rapini } from '../api/client.mjs';
import { notify } from '../helpers/index.mjs';
import { useTemplateConfig } from '../hooks/useConfigStore.mjs';
import { useDialog } from '../providers/DialogProvider';
import { theme } from '../theme';
import LoadingOverlay from './LoadingOverlay';
import PopoverInput from './PopoverInput';


function setRatioWarningMsg(img, artWidth, artHeight, setMsg) {
  // Includes "naturalWidth" being 0
  if (!img?.naturalWidth) {
    return;
  }
  const { naturalWidth: w, naturalHeight: h } = img;
  const traitRatio = w / h;
  const aspectRatio = artWidth / artHeight;

  const msg = aspectRatio !== traitRatio ?
    <div>
      The trait's aspect ratio doesn't match the template's aspect ratio.<br/>
      <strong>Trait:</strong> {traitRatio.toFixed(2)} ({w}x{h}) <strong>Template:</strong> {aspectRatio.toFixed(2)} ({artWidth}x{artHeight})
    </div>
    : '';

  setMsg(msg);
}

export function TraitPlaceholder() {
  return (
    <div className={classes.trait}>{null}</div>
  );
}

export default function Trait({ methods, dndProvided, trait }) {
  const {
    pauseEmbla,
    resumeEmbla,
    updateTraitStateAndQueryCache,
  } = methods;

  const { templateId } = useParams();
  const [config] = useTemplateConfig(templateId, ({ artWidth, artHeight }) => ({ artWidth, artHeight }));
  const traitImageRef = useRef(null);
  const [ratioWarning, setRatioWarning] = useState('');

  useEffect(() => {
    traitImageRef.current.onload = () => setRatioWarningMsg(traitImageRef.current, config.artWidth, config.artHeight, setRatioWarning);
  }, [traitImageRef.current]);

  useEffect(() => {
    setRatioWarningMsg(traitImageRef.current, config.artWidth, config.artHeight, setRatioWarning);
  }, [config.artWidth, config.artHeight]);

  const [editingRarity, setEditingRarity] = useState(false);
  const [rarity, setRarity] = useState(trait.rarity);

  const [editingName, setEditingName] = useState(false);
  const [traitName, setTraitName] = useState(trait.name);

  const updateTrait = rapini.mutations.useUpdateTrait(trait.id, {
    onSuccess: ({ success }, variables) => {
      if (!success) {
        return notify({ message: 'Trait could not be updated.' });
      }
      updateTraitStateAndQueryCache({ ...trait, ...variables});
    },
  });

  const updateTraitImage = rapini.mutations.useUpdateTraitWithUpload(trait.id, {
    onSuccess: ({ success, newAssetUrl }) => {
      if (!success) {
        return notify({ message: 'Trait image could not be changed.' });
      }
      updateTraitStateAndQueryCache({ ...trait, ...{ asset_url: newAssetUrl } });
    },
  });

  const [, { close, open, stopLoading }] = useDialog({
    confirmText: "Delete Trait",
    text: "The trait will be deleted.",
    title: "Irreversible Action",
  });

  const deleteTrait = rapini.mutations.useDeleteTrait(trait.id, {
    onSuccess: ({ success }) => {
      if (!success) {
        return notify({ message: 'The trait could not be deleted.' });
      }
      updateTraitStateAndQueryCache(trait, { removeTrait: true });
      close();
    },
    onError: () => {
      stopLoading();
    },
  });

  const isLoading = updateTrait.isPending ||  updateTraitImage.isPending ||  deleteTrait.isPending;

  function handleDeleteBtn() {
    deleteTrait.mutate();
  }

  function handleSaveNameBtn() {
    if (traitName === trait.name) {
      return setEditingName(false);
    }
    updateTrait.mutateAsync({ name: traitName })
      .then(() => {
        setEditingName(false);
      }).catch(() => {});
  }

  function handleSaveRarityBtn() {
    if (rarity === trait.rarity) {
      return setEditingRarity(false);
    }
    updateTrait.mutateAsync({ rarity: rarity })
      .then(() => {
        setEditingRarity(false);
      }).catch(() => {});
  }

  const debouncedUpdateRarity = useDebouncedCallback((value) => {
    if (value === trait.rarity) {
      return;
    }
    updateTrait.mutate({ rarity: value });
  }, 500);

  function handleSliderChange(value) {
    setRarity(value);
    debouncedUpdateRarity(value);
  }

  function closeNamePopover() {
    setEditingName(false);
    setTraitName(trait.name);
  }

  function closeRarityPopover() {
    setEditingRarity(false);
    setRarity(trait.rarity);
  }

  function handleChangeTraitImageBtn(file) {
    updateTraitImage.mutate({ file });
  }

  return (
    <Paper
      className={classes.trait}
      ref={dndProvided.innerRef}
      withBorder={true}
      {...dndProvided.draggableProps}
    >
      <Group justify="space-between" px={4}>
        <div className={classes.dragHandle} {...dndProvided.dragHandleProps}>
          <IconGripVertical size={16} cursor="grab" color={theme.colors.dark[1]} />
        </div>
        <ActionIcon.Group>
          <FileButton onChange={handleChangeTraitImageBtn} accept={IMAGE_MIME_TYPE}>
            {(props) =>
              <Tooltip label="Change trait image">
                <ActionIcon
                  {...props}
                  aria-label="Change trait image"
                  disabled={updateTraitImage.isPending}
                  size="md"
                  variant="transparent"
                  color={theme.colors.dark[1]}
                >
                  <IconPhotoUp size={16} />
                </ActionIcon>
              </Tooltip>
            }
          </FileButton>
          <Tooltip label="Delete trait">
            <ActionIcon
              aria-label="Delete trait"
              disabled={isLoading}
              onClick={open(handleDeleteBtn)}
              size="md"
              variant="transparent"
              color={theme.colors.dark[1]}
            >
              <IconTrash size={16} />
            </ActionIcon>
          </Tooltip>
        </ActionIcon.Group>
      </Group>
      <div className={classes.traitImageFrame}>
        <LoadingOverlay visible={updateTraitImage.isPending} />
        <Image ref={traitImageRef} src={trait.asset_url} className={classes.traitImage}/>
        {ratioWarning &&
          <Tooltip label={ratioWarning}>
            <ThemeIcon className={classes.ratioWarning} size="md" color="pink">
              <IconAlertTriangle size={16} />
            </ThemeIcon>
          </Tooltip>
        }
      </div>
      <Stack className={classes.traitData}>
        <Popover
          classNames={{ dropdown: classes.popoverDropdown }}
          onChange={closeNamePopover}
          opened={editingName}
          position="top"
          withArrow
          withinPortal={false}
        >
          <Popover.Target>
            <Group gap={0} wrap="nowrap" justify="center">
              <Tooltip label={trait.name} disabled={trait.name.length < 14}>
                <Text fw={800} size="sm" truncate="end">{trait.name}</Text>
              </Tooltip>
              <Tooltip label="Edit trait name" disabled={editingName}>
                <ActionIcon
                  aria-label="Edit trait name"
                  disabled={editingName}
                  onClick={() => setEditingName(true)}
                  variant="subtle"
                >
                  <IconPencil size={16} />
                </ActionIcon>
              </Tooltip>
            </Group>
          </Popover.Target>
          <Popover.Dropdown>
            <PopoverInput
              close={closeNamePopover}
              handleChange={(ev) => setTraitName(ev.currentTarget.value)}
              handleSaveBtn={handleSaveNameBtn}
              InputElement={TextInput}
              label="Save trait name"
              loading={updateTrait.isPending}
              value={traitName}
            />
          </Popover.Dropdown>
        </Popover>
        <Stack gap={0}>
          <Popover
            classNames={{ dropdown: classes.popoverDropdown }}
            onChange={closeRarityPopover}
            opened={editingRarity}
            position="top"
            withArrow
            withinPortal={false}
          >
            <Popover.Target>
              <Group gap={0} wrap="nowrap">
                <Text size="xs" fw={800} mr={4}>Rarity:</Text>
                <Text size="xs">{trait.rarity}%</Text>
                <Tooltip label="Set custom rarity">
                  <ActionIcon
                    aria-label="Set custom rarity"
                    disabled={editingRarity}
                    onClick={() => setEditingRarity(true)}
                    variant="subtle"
                  >
                    <IconPencil size={16} />
                  </ActionIcon>
                </Tooltip>
              </Group>
            </Popover.Target>
            <Popover.Dropdown>
              <PopoverInput
                close={closeRarityPopover}
                handleChange={setRarity}
                handleSaveBtn={handleSaveRarityBtn}
                inputProps={{ allowLeadingZeros: false, decimalScale: 6, min: 0.000001, max: 100, step: 0.01 }}
                InputElement={NumberInput}
                label="Save trait rarity"
                loading={updateTrait.isPending}
                value={rarity}
              />
            </Popover.Dropdown>
          </Popover>
          <Slider
            disabled={editingRarity}
            inverted
            label={null}
            min={1}
            onChange={handleSliderChange}
            onChangeEnd={resumeEmbla}
            onMouseDown={pauseEmbla}
            onTouchStart={pauseEmbla}
            value={rarity}
          />

        </Stack>
      </Stack>
    </Paper>
  );
}
