import { Container, Title } from '@mantine/core';
import { Helmet } from 'react-helmet-async';
import { useLoaderData } from 'react-router-dom';

import { SITE_TITLE } from '../constants.mjs';


export default function Collection() {
  const { collection } = useLoaderData();

  return (
    <Container style={{padding: '20px 0'}}>
      <Helmet>
        <title>"{collection.name}" | {SITE_TITLE}</title>
      </Helmet>
      <Title className="g-title" order={2} mb={10}>{collection.name}</Title>
      <Container p="1rem 2rem">

      </Container>
    </Container>
  );
}
