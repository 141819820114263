import { useState } from 'react';
import { ActionIcon, Group, Popover, Text, TextInput, Tooltip } from '@mantine/core';
import { useResizeObserver } from '@mantine/hooks';
import { IconPencil } from '@tabler/icons-react';

import classes from './Layer.module.css';
import traitClasses from './Trait.module.css';
import PopoverInput from './PopoverInput';


export default function LayerName({
  actionProps,
  inline = true,
  layer,
  textProps,
  updateLayer,
}) {
  const [editingTitle, setEditingTitle] = useState(false);
  const [layerName, setLayerName] = useState(layer.name);

  const [textRef] = useResizeObserver();
  const hideTooltip = textRef?.current?.offsetWidth >= textRef?.current?.scrollWidth;

  function handleEditTitleBtn() {
    setLayerName(layer.name);
    setEditingTitle(true);
  }

  function handleSaveTitleBtn() {
    // Do nothing if title has not changed
    if (layerName === layer.name) {
      return setEditingTitle(false);
    }
    updateLayer.mutateAsync({ name: layerName })
      .then(() => {
        setEditingTitle(false);
      });
  }

  function close() {
    setLayerName(layer.name);
    setEditingTitle(false);
  }

  const popoverTarget = <>
    <Tooltip label={layer.name} disabled={hideTooltip}>
      <Text
        className={`${classes.layerName} g-pE`}
        ref={textRef}
        truncate="end"
        {...textProps}
      >
        {layer.name}
      </Text>
    </Tooltip>
    <Tooltip label="Edit layer name">
      <ActionIcon
        aria-label="Edit layer name"
        className="g-pE"
        onClick={handleEditTitleBtn}
        variant="subtle"
        {...actionProps}
      >
        <IconPencil size={16} />
      </ActionIcon>
    </Tooltip>
  </>;

  const popoverDropdown = <PopoverInput
    actionProps={{ ml: 5 }}
    close={close}
    handleChange={ev => setLayerName(ev.currentTarget.value)}
    handleSaveBtn={handleSaveTitleBtn}
    inputProps={{ maw: '150px' }}
    InputElement={TextInput}
    label="Save layer title"
    loading={updateLayer.isPending}
    value={layerName}
  />;

  return (
    inline ?
      <Group wrap="nowrap" justify="center" gap={2} h={36}>
        {editingTitle ? popoverDropdown : popoverTarget}
      </Group>
      :
    <Popover
      classNames={{ dropdown: traitClasses.popoverDropdown }}
      onChange={close}
      opened={editingTitle}
      position="top"
      withArrow
      withinPortal={false}
    >
      <Popover.Target>
        <Group wrap="nowrap" justify="center" gap={2} h={36}>{popoverTarget}</Group>
      </Popover.Target>
      <Popover.Dropdown>
        {popoverDropdown}
      </Popover.Dropdown>
    </Popover>
  );
}
