import { useState } from 'react';
import {
  ActionIcon,
  Button,
  Chip,
  Container,
  Divider,
  Flex,
  Group,
  Modal,
  NumberInput,
  Text,
  Tooltip,
  useMantineTheme
} from '@mantine/core';
import { useParams } from 'react-router-dom';
import { IconArrowsHorizontal, IconArrowsVertical, IconPhoto, IconRefresh, IconRotate } from '@tabler/icons-react';

import classes from './CollectionTopBar.module.css';
import _classes from './GenerateModal.module.css';
import ArtPreview from './ArtPreview';
import useArtPreviews from '../hooks/useArtPreviews.mjs';


export default function GenerateModal({ close, data, opened }) {
  const {
    config,
    handleChange,
    handleChangeDelayed,
    setTemplateConfig,
    templateConfig,
  } = data;
  const theme = useMantineTheme();
  const { templateId } = useParams();
  const { previews, setRefresh } = useArtPreviews({ previewCount: 20, templateId });
  const [templateSelection, setTemplateSelection] = useState(`${templateId}`);

  return (
    <Modal
      fullScreen
      onClose={close}
      opened={opened}
      styles={{
        content: { backgroundColor: theme.other.heroDark },
        header: { backgroundColor: theme.colors.dark[9] },
        title: { fontWeight: 'bold', marginInline: 'auto' },
        close: { margin: 0, }
      }}
      title="Confirm Your Settings"
      transitionProps={{ transition: 'fade', duration: 200 }}
      zIndex={900}
    >
      <Container p="1rem">
        <Text mt={20} mb={25}>Please make sure you're happy with the previews and your settings.</Text>
        <Flex direction={{ base: 'column', xs: 'row' }} align="center" justify="center" gap="lg">
          <Flex direction="column" align="center" justify="flex-start" gap="md" mb={15}>
            <Text ta="center" mb={10} miw={350}>
              {templateSelection === 'all' ?
                'All templates will be generated.' :
                <span>The selected <strong>"Template #{templateId}"</strong> will be generated.</span>
              }
            </Text>
            <Chip.Group multiple={false} value={templateSelection} onChange={setTemplateSelection}>
              <Chip value={`${templateId}`} color={theme.colors.dark[9]}>Generate selected template only</Chip>
              <Chip value="all" color={theme.colors.dark[9]}>Generate all templates</Chip>
            </Chip.Group>
            <Divider mt={20} w='20%' miw={70} />
          </Flex>
          <Flex direction={{ base: 'column', md: 'row' }} align="stretch" justify="flex-start" gap="md">
            <NumberInput
              classNames={{ description: classes.nowrap, wrapper: _classes.input }}
              description="Number of arts to generate"
              label="Collection Size"
              leftSection={<IconPhoto size={14}/>}
              min={1}
              onChange={(value) => handleChange(setTemplateConfig, value || 1, 'collectionSize')}
              value={templateConfig.collectionSize}
            />
            <NumberInput
              classNames={{ description: classes.nowrap, wrapper: _classes.input }}
              description="Width for generated arts"
              label="Art Width"
              leftSection={<IconArrowsHorizontal size={14}/>}
              min={2}
              onChange={(value) => handleChangeDelayed((!value || value < 2) ? 2 : value, 'artWidth')}
              value={config.artWidth}
            />
            <NumberInput
              classNames={{ description: classes.nowrap, wrapper: _classes.input }}
              description="Height for generated arts"
              label="Art Height"
              leftSection={<IconArrowsVertical size={14}/>}
              min={2}
              onChange={(value) => handleChangeDelayed((!value || value < 2) ? 2 : value, 'artHeight')}
              value={config.artHeight}
            />
          </Flex>
        </Flex>
        <Flex mt="3rem" justify="center">
          <Button.Group>
            <Button
              leftSection={<IconRotate size={14}/>}
              maw={350}
              miw={220}
              onClick={() => {}}
              variant="gradient"
              w="50%"
            >
              Start Generating
            </Button>
            <Button
              onClick={close}
              variant="light"
            >
              Cancel
            </Button>
          </Button.Group>
        </Flex>
        <Group wrap="nowrap" justify="center" gap="xs" pt={40} pb={15} >
          <Text ta="center" my="0.5rem" size="sm">Art Preview</Text>
          <Tooltip label="Regenerate previews" withinPortal={false}>
            <ActionIcon
              aria-label="Regenerate previews"
              loading={false}
              onClick={() => setRefresh(val => !val)}
              size="md"
              variant="filled"
            >
              <IconRefresh size={16} />
            </ActionIcon>
          </Tooltip>
        </Group>
        <Flex gap={16} w="100%" h="100%" justify="center" m={0} maw="100%" pl={16} wrap="wrap">
          {Array.from(previews.entries()).map(([key, traits]) =>
            <ArtPreview key={key} traits={traits} withinPortal={false} />
          )}
        </Flex>
      </Container>
    </Modal>
  );
}
