import React from "react";


export default function SolanaLogo({ size }) {
  return (
    <svg width={size} fill="none" viewBox="0 0 101 88" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M100.48 69.382l-16.673 17.42c-.363.378-.801.68-1.288.886a3.959 3.959 0 01-1.545.312H1.936c-.378 0-.746-.107-1.062-.309a1.9 1.9 0 01-.714-.825 1.841 1.841 0 01-.132-1.072c.064-.362.235-.698.492-.966l16.686-17.42a3.872 3.872 0 011.284-.885 3.96 3.96 0 011.54-.313h79.034c.378 0 .746.107 1.062.308.315.202.563.489.714.826.15.337.196.709.132 1.071a1.87 1.87 0 01-.492.967zM83.807 34.303a3.873 3.873 0 00-1.288-.886 3.96 3.96 0 00-1.545-.312H1.936c-.378 0-.746.107-1.062.309a1.9 1.9 0 00-.714.825c-.15.337-.196.71-.132 1.071.064.362.235.698.492.967l16.686 17.42c.362.377.799.678 1.284.884a3.96 3.96 0 001.54.314h79.034c.378 0 .746-.107 1.062-.309.315-.201.563-.488.714-.825a1.844 1.844 0 00-.36-2.038l-16.673-17.42zM1.936 21.791h79.038a3.96 3.96 0 001.544-.313 3.873 3.873 0 001.289-.886l16.673-17.42a1.87 1.87 0 00.492-.967 1.843 1.843 0 00-.132-1.07 1.906 1.906 0 00-.714-.826A1.974 1.974 0 0099.064 0H20.03a3.96 3.96 0 00-1.54.314 3.873 3.873 0 00-1.284.884L.525 18.618a1.868 1.868 0 00-.492.966c-.065.362-.019.734.13 1.07.15.337.398.624.713.826.315.202.683.31 1.06.31z"
        fill="url(#a)"
      />
      <defs>
        <linearGradient
          id="a"
          gradientUnits="userSpaceOnUse"
          x1="8.526"
          x2="88.993"
          y1="90.097"
          y2="-3.016"
        >
          <stop offset=".08" stopColor="#9945FF" />
          <stop offset=".3" stopColor="#8752F3" />
          <stop offset=".5" stopColor="#5497D5" />
          <stop offset=".6" stopColor="#43B4CA" />
          <stop offset=".72" stopColor="#28E0B9" />
          <stop offset=".97" stopColor="#19FB9B" />
        </linearGradient>
      </defs>
    </svg>
  );
}
