import { Container, Text } from '@mantine/core';


export default function CollectionListError(props) {
  return (
    <Container p={20} ta="center" {...props}>
      <Text>Could not load your collection list. Please try again later.</Text>
    </Container>
  );
}
