import { useMemo, useState } from 'react';

import { rapini } from '../api/client.mjs';
import { PREVIEW_COUNT, PREVIEW_COUNT_FULLSCREEN } from '../constants.mjs';


const range = (n) => [...Array(n).keys()];

export default function useArtPreviews({
    fullscreen = false,
    previewCount: _previewCount = PREVIEW_COUNT,
    templateId,
}) {
    const [refresh, setRefresh] = useState(false);
    const { data: layerData = [], isLoading, isError } = rapini.queries.useGetTemplateLayers(templateId);

    const previews = useMemo(() => {
        const previews = new Map();

        // Use layer with traits, filter empty and hidden layers
        const layers = layerData.filter(layer => layer.traits.length > 0 && layer.hidden === 0);
        const layerTraitIndexes = layers.map(layer => range(layer.traits.length));
        const previewCount = Math.min(
            layers.reduce((total, layer) => total * layer.traits.length, (layers.length ? 1 : 0)),
            fullscreen ? PREVIEW_COUNT_FULLSCREEN : _previewCount
        );

        while (!isLoading && !isError && previewCount > 0 && previews.size < previewCount) {
            const previewKey = [];
            const traits = [];
            for (const [idx, indexes] of layerTraitIndexes.entries()) {
                // Skip if the layer has no traits
                if (indexes.length < 1) {
                    continue;
                }

                const randIdx = Math.floor(Math.random() * indexes.length);
                const traitIndex = indexes.splice(randIdx, 1).at(0);
                const selectedTrait = layers[idx].traits[traitIndex];

                previewKey.push(selectedTrait.id);
                traits.push({ ...selectedTrait, layerName: layers[idx].name });

                // If all traits are exhausted, replenish
                if (indexes.length < 1) {
                    layerTraitIndexes[idx] = range(layers[idx].traits.length);
                }
            }
            previews.set(previewKey.join(''), traits);
        }

        return previews;
    }, [fullscreen, layerData, refresh]);

    return {
        isError,
        isLoading,
        previews,
        setRefresh,
    };
}
