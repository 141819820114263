import { Button, Container, Group, Text } from "@mantine/core";
import { Helmet } from 'react-helmet-async';
import { useNavigate } from "react-router-dom";

import { SITE_TITLE } from '../constants.mjs';
import AppLayout from '../components/AppLayout';


export default function NotFound() {
  const navigate = useNavigate();

  return (
    <AppLayout>
      <Helmet>
        <title>Not Found | {SITE_TITLE}</title>
      </Helmet>
      <Container py={50}>
        <Text fz={72} ta="center" >404</Text>
        <Text size="xl" ta="center" >Page not found!</Text>
        <Group justify="center" mt={50}>
          <Button onClick={() => navigate('/')} size="md">Return to home page</Button>
        </Group>
      </Container>
    </AppLayout>
  );
}
