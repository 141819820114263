import { Container, Text } from '@mantine/core';
import { Helmet } from 'react-helmet-async';
import { useLoaderData, useParams } from 'react-router-dom';

import { SITE_TITLE } from '../constants.mjs';
import CollectionLayers from '../components/CollectionLayers';
import CollectionName from '../components/CollectionName';
import CollectionTopBar from '../components/CollectionTopBar';
import Panes from '../components/Panes';


export default function CollectionEdit() {
  const { collection } = useLoaderData();
  const { collectionId, templateId = -1 } = useParams();
  const selectedTemplateId = parseInt(templateId, 10);
  const selectedTemplate = collection?.templates?.find(t => t.template_id === selectedTemplateId);

  return (
    <Container style={{padding: '20px 0'}}>
      <Helmet>
        <title>Edit Collection | {SITE_TITLE}</title>
      </Helmet>
      <>
        <Text className="g-title" size="sm" mb="5">Collection Name:</Text>
        <CollectionName collection={collection} collectionId={collectionId} />
      </>
      <Container p="1rem 2rem">
        <CollectionTopBar collection={collection} />
        {selectedTemplate !== undefined && selectedTemplateId !== -1 &&
          <>
            <Panes/>
            <CollectionLayers/>
          </>
        }
      </Container>
    </Container>
  );
}
