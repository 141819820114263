import { Container } from "@mantine/core";

import Header from './Header';
import Footer from './Footer';


export default function AppLayout({ children }) {
  return (
    <Container className="g-bg" style={{ padding: 0, gridTemplateRows: 'auto 1fr auto' }} fluid mih="100vh" display="grid">
      <Header/>
      <div>{children}</div>
      <Footer/>
    </Container>
  );
}
