import { Burger, Container, Drawer, Flex, Group, ThemeIcon, Title } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { NavLink, useNavigate } from 'react-router-dom';
import { IconBinaryTree } from '@tabler/icons-react';

import classes from './Header.module.css';
import ConnectButton from './ConnectButton';


const navbarItems = [
  { link: '/collections', label: 'Collections' },
];

export default function Header() {
  const navigate = useNavigate();
  const [opened, { close, toggle }] = useDisclosure(false);
  const gradient = { from: '#9945FF', to: '#14F195', deg: 135 };

  const getNavbarItems = (extraClasses = '') => navbarItems.map((link) => (
    <NavLink
      onClick={() => extraClasses && close()}
      key={link.label}
      to={link.link}
      className={({isActive}) => `${extraClasses} ${classes.link}${isActive ? ` ${classes.active}` : ''}`}
    >
      {link.label}
    </NavLink>
  ));

  return (
    <header className={classes.header}>
      <Container size="md" className={classes.inner}>
        <Group style={{cursor: 'pointer'}} onClick={() => {
          navigate('/');
        }}>
          <ThemeIcon variant="gradient" size="xl" aria-label="NFTGen Logo" gradient={gradient}>
            <IconBinaryTree alignmentBaseline="middle" size={36}/>
          </ThemeIcon>
          <Title size="h1">NFTGen</Title>
        </Group>
        <Group gap={20} visibleFrom="xs">
          {getNavbarItems()}
          <ConnectButton variant="gradient" gradient={gradient} />
        </Group>

        <Burger opened={opened} onClick={toggle} hiddenFrom="xs" size="sm"/>
        <Drawer opened={opened} onClose={close} position="top" size="xs" classNames={{ header: classes.drawerHeader, content: classes.drawerContent }}>
          <Flex align="center" direction="column" gap={20}>
            <Group mb="2rem" style={{cursor: 'pointer'}} onClick={() => {
              close();
              navigate('/');
            }}>
              <ThemeIcon
                variant="gradient"
                size="lg"
                aria-label="NFTGen Logo"
                gradient={gradient}
              >
                <IconBinaryTree alignmentBaseline="middle" size={24}/>
              </ThemeIcon>
              <Title size="h3">NFTGen</Title>
            </Group>
            {getNavbarItems(classes.hamburger)}
            <span onClick={() => close()}><ConnectButton size="md" variant="gradient" gradient={gradient} /></span>
          </Flex>
        </Drawer>
      </Container>
    </header>
  );
}
