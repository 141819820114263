const OK = 'OK';

async function get(url, queryParams, opts = {}) {
    let query = '';
    if (queryParams) {
        query = `?${(new URLSearchParams(queryParams)).toString()}`;
    }
    return request('get', `${url}${query}`, opts);
}

async function postJson(url, body, opts = {}) {
    ({ body, opts } = _prepareJsonRequest(body, opts));
    return post(url, body, opts);
}

async function post(url, body, opts = {}) {
    return request('post', url, { body, ...opts })
}

async function putJson(url, body, opts = {}) {
    ({ body, opts } = _prepareJsonRequest(body, opts));
    return request('put', url, { body, ...opts });
}

async function put(url, body, opts = {}) {
    return request('put', url, { body, ...opts })
}

async function delete_(url, opts = {}) {
    return request('delete', url, { ...opts });
}

function _prepareJsonRequest(body, opts) {
    // Default headers
    const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    };
    // For test environment
    try {
        headers['X-CSRF-TOKEN'] = document?.querySelector('meta[name="csrf-token"]')?.getAttribute('content');
    } catch (err) {}

    opts.headers = Object.assign(opts.headers || {}, headers, opts.header || {});
    body = JSON.stringify(body);

    return {
        body,
        opts,
    };
}

async function request(method, url, opts) {
    const requestInit = { method };

    if (opts?.body) {
        requestInit.body = opts.body;
    }
    if (opts?.headers) {
        requestInit.headers = opts.headers;
    }

    const response = await fetch(url, requestInit);
    if (!response.ok) {
        // For test environment
        try {
            process?.env;
            console.log(`[Request] Status: ${response.status} ${response.statusText}`);
            console.log(`[Request] Response Body: ${await response.text()}`);
            return response.status;
        } catch (err) {}

        return false;
    }

    const body = opts?.raw ? await response.text() : await response.json();
    if (opts?.returnHeaders) {
        return {
            headers: response.headers,
            body,
        };
    }

    return body;
}


export default {
    delete_,
    get,
    OK,
    post,
    postJson,
    put,
    putJson,
    request,
}
