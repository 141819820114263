import { Container } from '@mantine/core';

import { rapini } from '../api/client.mjs';
import CollectionSkeleton from './CollectionSkeleton';
import CollectionListError from './errors/CollectionListError';
import CollectionListItem from './CollectionListItem';
import CreateCollectionArrow from './CreateCollectionArrow';


export default function CollectionList(props) {
  const { data: collections, isLoading, isError } = rapini.queries.useListCollections();

  return (
    <Container {...props}>
      {isLoading && <CollectionSkeleton/>}
      {isError && <CollectionListError/>}
      {!isLoading && !isError && Array.isArray(collections) &&
        (collections.length > 0 ?
          collections.map(col => <CollectionListItem key={col.id} collection={col} />) :
          <CreateCollectionArrow/>
        )
      }
    </Container>
  );
}
