import { useQueryClient } from '@tanstack/react-query';

import { rapini } from '../api/client.mjs';
import { notify } from '../helpers/index.mjs';


export default function useSetLayerOrder(templateId, layers) {
    const queryClient = useQueryClient();

    const updateTemplate = rapini.mutations.useUpdateTemplate(templateId, {
        onSuccess: ({ success }) => {
            if (!success) {
                return notify({ message: 'Layer order could not be saved.' });
            }
            reloadLayers();
        },
    });

    function reloadLayers() {
        return queryClient.invalidateQueries({ queryKey: rapini.queryKeys.getTemplateLayers(templateId) })
            .catch(err => {
                notify({ message: `Template layers could not be reloaded. ${err?.message || ''}` });
            });
    }

    function getNewLayerOrder() {
        const newLayerIdOrder = [];

        // If the template has some layers
        if (layers?.length) {
            newLayerIdOrder.push(...layers.map(layer => layer.id));
        }

        return newLayerIdOrder;
    }

    function add(id, newLayerIndex) {
        const layerIdOrder = getNewLayerOrder();
        layerIdOrder.splice(newLayerIndex, 0, id);
        return updateTemplate.mutateAsync({ layer_order: layerIdOrder.join(',') });
    }

    function move(id, newIndex) {
        const layerIdOrder = getNewLayerOrder();
        layerIdOrder.splice(layerIdOrder.indexOf(id), 1);
        layerIdOrder.splice(newIndex, 0, id);
        return updateTemplate.mutateAsync({ layer_order: layerIdOrder.join(',') });
    }

    function remove(id) {
        const layerIdOrder = getNewLayerOrder();
        layerIdOrder.splice(layerIdOrder.indexOf(id), 1);
        return updateTemplate.mutateAsync({ layer_order: layerIdOrder.join(',') });
    }

    return {
        isPending: updateTemplate.isPending,
        add,
        move,
        remove,
    };
}
