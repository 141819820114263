import { redirect } from 'react-router-dom';

import { rapini } from '../api/client.mjs';
import { PUB_ID } from '../constants.mjs';


const SELECTED_TEMPLATE_ID = 'SELECTED_TEMPLATE_ID';

export default async function loader({ params }) {
    let { collectionId, templateId: _templateId } = params;
    const templateId = parseInt(_templateId, 10);
    const localStorageKey = `${window.localStorage.getItem(PUB_ID)}.${SELECTED_TEMPLATE_ID}_FOR_${collectionId}`;
    const collection = await rapini.requests.getCollectionWithTemplates(collectionId);

    if (templateId) {
        const template = collection.templates.find(t => t.template_id === templateId);
        if (!template) {
            throw new Error('Not Found');
        }
    } else {
        const selectedTemplateId = parseInt(window.localStorage.getItem(localStorageKey), 10);
        // Checks first if the previously selected template still exists
        const templateId = selectedTemplateId > 0 && collection.templates.find(t => t.template_id === selectedTemplateId) ?
            selectedTemplateId :
            collection.templates.at(0)?.template_id || -1;

        if (templateId > 0) {
            return redirect(`/collection/${collectionId}/template/${templateId}/edit`);
        }
        if (templateId === -1) {
            // No template selected state
            return { collection };
        }
    }

    // Only sets if templateId has a positive value
    window.localStorage.setItem(localStorageKey, templateId);
    return { collection };
}
