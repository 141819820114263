import { LoadingOverlay as _LoadingOverlay } from '@mantine/core';


export default function LoadingOverlay({ visible }) {
  return (
    <_LoadingOverlay
      visible={visible}
      overlayProps={{ blur: 5 }}
      styles={{ overlay: { backgroundColor: 'transparent', opacity: '0.5' }}}
    />
  );
}
