import { Container, Flex, Skeleton } from '@mantine/core';


export default function CollectionSkeleton(props) {
  return (
    <Flex p="sm" gap="md" {...props}>
      <Container w="20%" miw="110">
        <Skeleton height={110} width={110} />
        <Flex gap="xs" mt={10}>
          <Skeleton height={30} width={30} />
          <Skeleton height={30} width={30} />
          <Skeleton height={30} width={30} />
        </Flex>
      </Container>
      <Flex align="flex-end" w="80%" direction="column" gap={30}>
        <Skeleton height={4} width="100%" />
        <Skeleton height={4} width="80%" />
        <Skeleton height={4} width="60%" />
        <Skeleton height={4} width="50%" />
      </Flex>
    </Flex>
  );
}
