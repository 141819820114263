import { Container, Image } from '@mantine/core';


export default function CreateCollectionArrow(props) {
  return (
    <Container {...props} style={{ whiteSpace: "nowrap" }}>
      <Image src="/start-by-creating-your-first-collection.svg" display="inline" w="50vw" miw={240} maw={500} />
      <Image src="/arrow-up.png" display="inline" style={{ marginLeft: 10 }} w="7vw" miw={30} maw={80} />
    </Container>
  );
}
