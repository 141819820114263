import { Container, Group, Image, Title, Text } from '@mantine/core';

import classes from './Hero.module.css';
import SolanaLogo from './svg/SolanaLogo';
import ConnectButton from './ConnectButton';


export default function Hero() {
  return (
    <div className={classes.root}>
      <Container size="lg">
          <Group className={classes.inner} preventGrowOverflow={false} wrap="nowrap" justify="space-around" grow={true}>
            <div className={classes.content}>
              <Title className={classes.title}>
                Create and manage{' '}
                <Text
                  component="span"
                  inherit
                  variant="gradient"
                  gradient={{from: 'pink', to: 'yellow'}}
                >
                  NFT
                </Text>
                {' '}collections on <Group className={classes.solanaGroup} gap={10}>Solana <SolanaLogo size={48} /></Group>
              </Title>
              <ConnectButton
                variant="gradient"
                gradient={{from: 'pink', to: 'yellow'}}
                size="xl"
                className={classes.connect}
                mt={40}
              />
            </div>

            <Image radius={5} className={classes.image} src="/tmp-app-ss.png"/>
          </Group>
      </Container>
    </div>
  );
}
