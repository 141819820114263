import { Flex, Skeleton } from '@mantine/core';


export default function PageSkeleton() {
  return (
    <Flex align="flex-end" direction="column" gap={40} p={50}>
      <Skeleton height={4} width="80%" />
      <Skeleton height={4} width="80%" />
      <Skeleton height={4} width="80%" />
      <Skeleton height={4} width="80%" />
      <Skeleton height={4} width="60%" />
      <Skeleton height={4} width="60%" />
      <Skeleton height={4} width="60%" />
      <Skeleton height={4} width="60%" />
      <Skeleton height={4} width="60%" />
      <Skeleton height={4} width="60%" />
    </Flex>
  );
}
