// Adapted from: https://github.com/lukechilds/merge-images
const defaultOptions = {
    format: 'image/png',
    quality: 0.92,
    width: undefined,
    height: undefined,
    crossOrigin: undefined
};

// Return Promise
const mergeImages = (sources = [], options = {}) => new Promise(resolve => {
    options = Object.assign({}, defaultOptions, options);

    // Load sources
    const images = sources.map(source => new Promise((resolve, reject) => {
        // Convert sources to objects
        if (source.constructor.name !== 'Object') {
            source = { src: source };
        }

        // Resolve source and img when loaded
        const img = new window.Image();
        img.crossOrigin = options.crossOrigin;
        img.onerror = () => reject(new Error('Couldn\'t load image'));
        img.onload = () => resolve(Object.assign({}, source, { img }));
        img.src = source.src;
    }));

    const canvas = window.document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    // When sources have loaded
    resolve(Promise.all(images)
        .then(images => {
            // Set canvas dimensions
            canvas.width = options['width'];
            canvas.height = options['height'];

            // Check if there is an image with a different aspect ratio
            const ratio = canvas.width / canvas.height;
            const hasDifferentRatio = images.some(image => ratio !== image.img.width / image.img.height);

            // Draw images to canvas
            images.forEach(image => {
                ctx.globalAlpha = image.opacity ? image.opacity : 1;
                return ctx.drawImage(image.img, 0, 0, options.width, options.height);
            });

            return {
                hasDifferentRatio,
                url: canvas.toDataURL(options.format, options.quality), // Resolve all other data URIs sync
            };
        }));
});


export default mergeImages;
