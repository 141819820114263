import { Draggable, Droppable } from '@hello-pangea/dnd';
import { Carousel } from '@mantine/carousel';

import classes from './LayerTraits.module.css';
import Trait, { TraitPlaceholder } from './Trait';


export default function LayerTraits({ layerId, methods, setEmbla, traits }) {
  const {
    pauseEmbla,
    resumeEmbla,
    updateTraitStateAndQueryCache,
  } = methods;

  return (
    <Droppable
      direction="horizontal"
      droppableId={`${layerId}`}
      mode="virtual"
      renderClone={(provided, snapshot, rubric) => {
        return (
          <Trait
            methods={{ pauseEmbla, resumeEmbla, updateTraitStateAndQueryCache }}
            dndProvided={provided}
            trait={traits[rubric.source.index]}
          />
        );
      }}
    >
      {(provided, snapshot) => (
        <div className={classes.traitsCon} ref={provided.innerRef} {...provided.droppableProps}>
          <Carousel
            align="start"
            classNames={{
              controls: classes.controls,
              control: classes.control,
              indicators: classes.indicators,
              slide: classes.slide,
              viewport: classes.viewport,
            }}
            controlsOffset="xs"
            controlSize={48}
            dragFree
            getEmblaApi={setEmbla}
            inViewThreshold={1}
            slideSize={0} /* sets flex-basis for Carousel.Slide items */
            slideGap="lg"
            withControls={Boolean(traits.length)}
            withIndicators={Boolean(traits.length)}
          >
            {traits.map((trait, index) =>
              <Carousel.Slide key={`${layerId}${trait.id}`}>
                <Draggable index={index} draggableId={`${layerId}_${trait.id}`}>
                  {(provided) => (
                    <Trait
                      methods={{ pauseEmbla, resumeEmbla, updateTraitStateAndQueryCache }}
                      dndProvided={provided}
                      trait={trait}
                    />
                  )}
                </Draggable>
              </Carousel.Slide>
            )}
            {snapshot.isUsingPlaceholder && !snapshot.draggingFromThisWith && <TraitPlaceholder/>}
          </Carousel>
        </div>
      )}
    </Droppable>
  );
}
