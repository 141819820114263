import { useContext } from 'react';
import { Button } from '@mantine/core';

import { AuthContext, logOut, signInWithWallet } from '../providers/AuthProvider';


export default function ConnectButton(props) {
  const { loggedIn, provider } = useContext(AuthContext);
  const { gradient } = props;
  const loggedInGradient = Object.assign({}, gradient, {from: 'rgba(64, 64, 64, 1)', to: 'rgba(94, 94, 94, 1)'});

  return (
    <Button
      onClick={() => loggedIn ? logOut() : signInWithWallet(provider, window.location.pathname)}
      {...props}
      gradient={loggedIn ? loggedInGradient : gradient}
    >
      {loggedIn ? 'Disconnect' : 'Connect Wallet to Start'}
    </Button>
  );
}
