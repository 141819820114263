import { Button, Container, Title } from '@mantine/core';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { IconCirclePlus } from '@tabler/icons-react';

import classes from './Collections.module.css';
import { rapini } from '../api/client.mjs';
import { SITE_TITLE } from '../constants.mjs';
import CollectionList from '../components/CollectionList';
import { notify } from '../helpers';


export default function Collections() {
  const navigate = useNavigate();

  const createCollection = rapini.mutations.useCreateCollection({
    onSuccess: ({ id }) => {
      if (!id) {
        return notify({ message: 'A new collection could not be created.' });
      }
      navigate(`/collection/${id}/edit`);
    },
  });

  function handleCreateBtn() {
    // todo: add a differentiator prefix to the name
    createCollection.mutate({ name: 'My Collection' });
  }

  return (
    <Container style={{ padding: '20px 0' }}>
      <Helmet>
        <title>Collections | {SITE_TITLE}</title>
      </Helmet>
      <Title className="g-title" order={2} mb={10}>Collections</Title>
      <Button
        disabled={createCollection.isPending}
        onClick={handleCreateBtn}
        display="block" mx="auto" leftSection={<IconCirclePlus size={24} />}
        size="md" w="50%" miw={300} variant="gradient"
        gradient={{ from: 'blue', to: 'cyan' }}
      >
        Create Collection
      </Button>
      <CollectionList mt={40} />
    </Container>
  );
}
