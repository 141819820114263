import { rapini } from '../api/client.mjs';
import { notify } from '../helpers/index.mjs';
import { useDialog } from '../providers/DialogProvider.jsx';


export default function useDeleteLayer(layerOrder) {
    const [, { open: modalOpen, close: closeModal, stopLoading: stopModalLoading }] = useDialog({
        confirmText: "Delete Layer",
        text: "The layer with all its traits will be deleted.",
        title: "Irreversible Action",
    });

    function deleteLayer(layerId) {
        if (!layerId) {
            return;
        }
        rapini.requests.deleteLayer(layerId)
            .then(({ success }) => {
                if (!success) {
                    throw new Error('Server response: Unsuccessful');
                }
                return layerOrder.remove(layerId);
            }).then(() => {
                closeModal();
            }).catch(err => {
                stopModalLoading();
                notify({ message: `Failed to delete the layer. ${err?.message || ''}` });
                console.error(err); // note: sentry?
            });
    }

    return function handleDeleteLayerBtn(layerId) {
        modalOpen(deleteLayer, [layerId])();
    }
}
