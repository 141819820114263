import { Container, Image } from '@mantine/core';
import { useContext } from 'react';

import { AuthContext } from '../providers/AuthProvider';
import CallToAction from './CallToAction';


export default function ProtectedView({ children }) {
  const { loggedIn } = useContext(AuthContext);

  // todo: update design/replace tmp image
  if (!loggedIn) {
    return (
      <>
        <CallToAction mt={70} />
        <Container style={{ paddingBottom: 80,  backgroundColor: 'var(--mantine-color-body-alt)' }} fluid>
          <Image src="/tmp-app-ss.png" maw={400} mx="auto" />
        </Container>
      </>
    );
  }

  return (<>
    {children}
  </>);
}
