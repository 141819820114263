import { ActionIcon, Container, Flex, Group, ScrollArea, Skeleton, Text, Tooltip } from '@mantine/core';
import { Carousel } from '@mantine/carousel';
import { useFullscreen } from '@mantine/hooks';
import { useParams } from 'react-router-dom';
import { IconMaximize, IconRefresh } from '@tabler/icons-react';

import classes from './ArtPreviewPane.module.css'
import { PREVIEW_COUNT } from '../constants.mjs';
import useArtPreviews from '../hooks/useArtPreviews.mjs';
import ArtPreview from './ArtPreview';


export default function ArtPreviewPane() {
  const { templateId: selectedTemplateId } = useParams();
  const { ref: refFullscreenCon, toggle: toggleMaximize, fullscreen } = useFullscreen();
  const { previews, setRefresh, isLoading, isError } = useArtPreviews({ fullscreen, templateId: selectedTemplateId });

  return (
    <Container className={classes.container} ref={refFullscreenCon}>
      <Group wrap="nowrap" justify="center" gap="xs">
        <Text ta="center" my="0.5rem" size="sm">Art Preview</Text>
        <Tooltip label="Regenerate previews" withinPortal={!fullscreen}>
          <ActionIcon
            aria-label="Regenerate previews"
            loading={false}
            onClick={() => setRefresh(val => !val)}
            size="md"
            variant="filled"
          >
            <IconRefresh size={16} />
          </ActionIcon>
        </Tooltip>
      </Group>
      {fullscreen ?
        <ScrollArea
          classNames={{ scrollbar: 'g-scrollbar'}}
          h="inherit"
          offsetScrollbars
          scrollbars="y"
          type="auto"
        >
          <Flex gap={16} w="100%" h="100%" m={0} maw="100%" pl={16} wrap="wrap">
            {Array.from(previews.entries()).map(([key, traits]) =>
              <ArtPreview key={key} traits={traits} withinPortal={!fullscreen} />
            )}
          </Flex>
        </ScrollArea>
        :
        (!isLoading && !previews.size ?
          <Flex justify="center" align="center" h="inherit">
            <Text size="sm" color="var(--mantine-color-dimmed)" bd="1px solid #243a59" p="1rem 1.5rem" mt={17}>No Previews</Text>
          </Flex>
          :
          <Carousel
            align="start"
            classNames={{
              indicators: classes.indicators,
            }}
            dragFree
            inViewThreshold={1}
            slideGap="lg"
            slideSize={0}
            withIndicators={true}
            withControls={false}
          >
            {isLoading && Array.from({length: PREVIEW_COUNT}, (_, idx) =>
              <Carousel.Slide key={idx}>
                <Skeleton className={classes.skeleton}/>
              </Carousel.Slide>
            )}
            {!isLoading && !isError && Array.from(previews.entries()).map(([key, traits]) =>
              <Carousel.Slide key={key}>
                <ArtPreview traits={traits} />
              </Carousel.Slide>
            )}
          </Carousel>
        )
      }
      <Group justify="flex-end">
        <Tooltip label="Fullscreen preview pane" withinPortal={!fullscreen}>
          <ActionIcon
            aria-label="Fullscreen preview pane"
            disabled={!previews.size}
            onClick={toggleMaximize}
            size="md"
            variant="filled"
          >
            <IconMaximize size={16} />
          </ActionIcon>
        </Tooltip>
      </Group>
    </Container>
  );
}
