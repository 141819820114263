import { useState } from 'react';
import { ActionIcon, Group, TextInput, Title, Tooltip } from '@mantine/core';
import { IconPencil } from '@tabler/icons-react';
import { useQueryClient } from '@tanstack/react-query';

import { rapini } from '../api/client.mjs';
import { notify } from '../helpers/index.mjs';
import PopoverInput from './PopoverInput';


export default function CollectionName({ collection: _collection, collectionId }) {
  const [editingTitle, setEditingTitle] = useState(false);
  const [titleValue, setTitleValue] = useState('');

  const queryClient = useQueryClient();
  const { data: collection = _collection } = rapini.queries.useGetCollection(collectionId);

  const updateCollection = rapini.mutations.useUpdateCollection(collectionId, {
    onSuccess: ({ success }, variables) => {
      if (!success) {
        return notify({ message: 'Collection could not be updated.' });
      }
      const updatedData = { ...collection, ...variables };
      queryClient.setQueryData(rapini.queryKeys.getCollection(collectionId), updatedData);
      setEditingTitle(false);
    },
  });

  function handleEditTitleBtn() {
    setTitleValue(collection.name);
    setEditingTitle(true);
  }

  function handleSaveTitleBtn() {
    // Do nothing if title has not changed
    if (collection.name === titleValue) {
      return setEditingTitle(false);
    }
    updateCollection.mutate({ name: titleValue });
  }

  return (
    <Group wrap="nowrap" gap={10}>
      {editingTitle ?
          <PopoverInput
            actionProps={{ ml: 10, mt: -4, size: 'lg' }}
            cancelProps={{ mt: -4, size: 'lg' }}
            close={() => setEditingTitle(false)}
            handleChange={ev => setTitleValue(ev.currentTarget.value)}
            handleSaveBtn={handleSaveTitleBtn}
            InputElement={TextInput}
            inputProps={{ className: 'g-padFix', mb: 6, size: 'md' }}
            label="Save Title"
            loading={updateCollection.isPending}
            value={titleValue}
          />
          :
          <Title className="g-title" order={2} mb={10}>
            {collection.name}
            {' '}
            <Tooltip label="Edit Title">
              <ActionIcon variant="subtle" onClick={handleEditTitleBtn} aria-label="Edit Title">
                <IconPencil stroke={1.5}/>
              </ActionIcon>
            </Tooltip>
          </Title>
      }
    </Group>
  );
}
