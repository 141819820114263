// localstorage keys
export const API_KEY = 'apiKey';
export const PUB_ID = 'pubId';

export const GENERAL_CONFIG = 'GENERAL_CONFIG';
export const TEMPLATE_CONFIG = 'TEMPLATE_CONFIG';

export const SITE_TITLE = 'NFTGen - Create and manage NFT collections on Solana';

export const PREVIEW_COUNT = 5;
export const PREVIEW_COUNT_FULLSCREEN = 55;
