import { useState } from 'react';
import { Button, Flex, NumberInput, Paper, Switch } from '@mantine/core';
import { useDebouncedCallback, useDisclosure } from '@mantine/hooks';
import { useParams } from 'react-router-dom';
import { IconArrowsHorizontal, IconArrowsVertical, IconPhoto, IconRotate } from '@tabler/icons-react';

import classes from './CollectionTopBar.module.css';
import { useGeneralConfig, useTemplateConfig } from '../hooks/useConfigStore.mjs';
import GenerateModal from './GenerateModal';
import TemplateCombobox from './TemplateCombobox';


export default function CollectionTopBar({ collection }) {
  const [generalConfig, setGeneralConfig] = useGeneralConfig();
  const { templateId } = useParams();
  const [templateConfig, setTemplateConfig] = useTemplateConfig(templateId);
  const [modalOpened, { close: closeModal, toggle: toggleModal }] = useDisclosure(false);

  const [config, setConfig] = useState({
    artWidth: templateConfig.artWidth,
    artHeight: templateConfig.artHeight,
  });

  const updateTemplateConfig = useDebouncedCallback((value, prop) => {
    setTemplateConfig(config => config[prop] = value);
  }, 500);

  function handleChangeDelayed(value, prop) {
    setConfig(config => ({ ...config, ...{ [prop]: value }}));
    updateTemplateConfig(value, prop);
  }

  function handleChange(setConfig, value, prop) {
    setConfig(config => config[prop] = value);
  }

  return (
    <Paper p="1rem" withBorder={true} className="g-bg">
      <GenerateModal
        close={closeModal}
        data={{
          config,
          handleChange,
          handleChangeDelayed,
          setTemplateConfig,
          templateConfig,
        }}
        opened={modalOpened}
      />
      <Flex direction={{ base: 'column', xs: 'row' }} align="center" justify="center" gap="md">
        <Flex direction={{ base: 'column', sm: 'row' }} align="stretch" justify="flex-start" gap="md">
          <TemplateCombobox collection={collection} />
          <NumberInput
            classNames={{ description: classes.nowrap, wrapper: classes.input }}
            description="Number of arts to generate"
            disabled={!templateId}
            label="Collection Size"
            leftSection={<IconPhoto size={14}/>}
            min={1}
            onChange={(value) => handleChange(setTemplateConfig, value || 1, 'collectionSize')}
            value={templateConfig.collectionSize}
          />
        </Flex>
        <Flex direction={{ base: 'column', sm: 'row' }} align="stretch" justify="flex-start" gap="md">
          <NumberInput
            classNames={{ description: classes.nowrap, wrapper: classes.input }}
            description="Width for generated arts"
            disabled={!templateId}
            label="Art Width"
            leftSection={<IconArrowsHorizontal size={14}/>}
            min={2}
            onChange={(value) => handleChangeDelayed((!value || value < 2) ? 2 : value, 'artWidth')}
            value={config.artWidth}
          />
          <NumberInput
            classNames={{ description: classes.nowrap, wrapper: classes.input }}
            description="Height for generated arts"
            disabled={!templateId}
            label="Art Height"
            leftSection={<IconArrowsVertical size={14}/>}
            min={2}
            onChange={(value) => handleChangeDelayed((!value || value < 2) ? 2 : value, 'artHeight')}
            value={config.artHeight}
          />
        </Flex>
      </Flex>
      <Flex mt="1.8rem" justify="center">
        <Button
          leftSection={<IconRotate size={14}/>}
          maw={350}
          miw={220}
          onClick={toggleModal}
          variant="gradient"
          w="50%"
        >
          Generate
        </Button>
      </Flex>
      <Flex
        align={{ base: 'flex-start', xs: 'center' }}
        direction={{ base: 'column', xs: 'row' }}
        gap="md"
        justify="center"
        maw={{ base: 160, xs: '100%' }}
        mt="2rem"
        mx="auto"
      >
        <Switch
          checked={generalConfig.artPreview}
          classNames={{ track: classes.switchTrack }}
          label="Art Preview"
          offLabel="OFF"
          onChange={(ev) => handleChange(setGeneralConfig, ev.currentTarget.checked, 'artPreview')}
          onLabel="ON"
        />
        <Switch
          checked={generalConfig.layersOverview}
          classNames={{ track: classes.switchTrack }}
          label="Layers Overview"
          offLabel="OFF"
          onChange={(ev) => handleChange(setGeneralConfig, ev.currentTarget.checked, 'layersOverview')}
          onLabel="ON"
        />
      </Flex>
    </Paper>
  );
}
