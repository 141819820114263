import { notifications } from '@mantine/notifications';

import { PUB_ID, TEMPLATE_CONFIG } from '../constants.mjs';
import { theme } from '../theme.js';


export function notify({ message, title = 'An error occurred', color = 'pink' }) {
    notifications.show({
        color,
        message,
        title,
        styles: {
            root: { backgroundColor: theme.colors.dark[9] },
        },
    });
}

export function isBetweenClasses(el, targetClass, limitClass) {
    let elCl = '';
    // "!elCl" check is for elements with non-string className attribute (e.g. SVGElement)
    while (el && (!elCl?.includes(limitClass) || !elCl)) {
        elCl = el?.className;
        if (elCl && elCl.includes(targetClass)) {
            return true;
        }
        el = el.parentElement;
    }
    return false;
}

export function getTemplateConfigKey(templateId) {
    return `${TEMPLATE_CONFIG}${templateId ? `_${templateId}` : ''}`;
}

export function duplicateTemplateConfig(sourceTemplateId, targetTemplateId) {
    const config = window.localStorage.getItem(
        `${window.localStorage.getItem(PUB_ID)}.${getTemplateConfigKey(sourceTemplateId)}`
    );
    window.localStorage.setItem(
        `${window.localStorage.getItem(PUB_ID)}.${getTemplateConfigKey(targetTemplateId)}`, config
    );
}
