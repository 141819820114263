import { useEffect, useRef, useState } from 'react';
import { ActionIcon, Badge, Button, Group, Paper, Stack, Text, Tooltip } from '@mantine/core';
import { Dropzone, IMAGE_MIME_TYPE } from '@mantine/dropzone';
import { IconArrowDown, IconArrowUp, IconCirclePlus, IconEye, IconEyeOff, IconX, IconTrash, IconUpload } from '@tabler/icons-react';

import classes from './Layer.module.css';
import { rapini } from '../api/client.mjs';
import { notify } from '../helpers/index.mjs';
import LayerName from './LayerName';
import LayerTraits from './LayerTraits';


export default function Layer({ endIdx, idx, layer, layerInnerMethods, methods }) {
  const {
    addAfter,
    handleDeleteLayerBtn,
    isPending,
    moveDown,
    moveUp,
    setTemplateLayerQueryData,
  } = methods;
  const dropboxOpenRef = useRef(null);
  const [traits, setTraits] = useState(layer.traits);

  const updateLayer = rapini.mutations.useUpdateLayer(layer.id, {
    onSuccess: ({ success }, variables) => {
      if (!success) {
        return notify({ message: 'Layer could not be updated.' });
      }
      setTemplateLayerQueryData({ ...layer, ...variables });
    },
  });

  const [embla, setEmbla] = useState(null);
  function pauseEmbla() {
    embla.internalEngine().dragHandler.removeAllEvents();
  }
  function resumeEmbla() {
    embla.internalEngine().dragHandler.addActivationEvents();
  }

  layerInnerMethods[layer.id] = {
    pauseEmbla,
    resumeEmbla,
    setTraits,
  };

  const [scrollCarousel, setScrollCarousel] = useState(false);
  useEffect(() => {
    if (!scrollCarousel) {
      return;
    }
    if (embla.slidesNotInView().length > 0) {
      embla.scrollTo(traits.length - embla.slidesInView().length);
    }
    setScrollCarousel(false);
  }, [scrollCarousel]);

  const createTrait = rapini.mutations.useCreateTrait({
    onSuccess: async ({ trait }) => {
      if (!trait) {
        return notify({ message: 'Trait could not be created.' });
      }
      setTraits(traits => [...traits, trait]);
    },
  });

  function handleOnDrop(files) {
    Promise.all(files.map(file => createTrait.mutateAsync({ layer_id: layer.id, file: file })))
      .then(results => {
        // Setting query cache at once
        const updatedLayer = {...layer, ...{ traits: [...layer.traits, ...results.map(r => r.trait)] }};
        setTemplateLayerQueryData(updatedLayer);

        // Uploading of all files is completed
        const l = files.length;
        const i = 3; // Number of files to show
        const filenamesToShow = files.slice(0, i).map(f => f.name).join(', ');
        notify({
          color: '#fff',
          message: `${filenamesToShow}${l > i ? ` and ${l - i} more file${l - i > 1 ? 's' : ''}` : ''} ${l > 1 ? 'are' : 'is'} uploaded.`,
          title: 'Traits uploaded',
        });
        setScrollCarousel(true);
      }).catch(() => {});
  }

  function updateTraitStateAndQueryCache(updatedTrait, { removeTrait } = { removeTrait: false }) {
    const traitIndex = traits.findIndex(trait => trait.id === updatedTrait.id);
    if (traitIndex === undefined || traitIndex < 0) {
      return;
    }

    const _traits = traits.slice();
    if (removeTrait) {
      _traits.splice(traitIndex, 1);
    } else {
      _traits.splice(traitIndex, 1, updatedTrait);
    }

    const updatedLayer = {...layer, ...{ traits: _traits }};
    setTraits(_traits);
    setTemplateLayerQueryData(updatedLayer);
  }

  function handleHideBtn({ hidden }) {
    updateLayer.mutate({ hidden: Number(!hidden) });
  }

  return (
    <Stack mb="1rem" gap="1rem" align="center">
      <Paper withBorder={true} className={classes.paper}>
        <Dropzone
          activateOnClick={false}
          classNames={{
            inner: classes.dropzoneInner,
            root: classes.dropzoneRoot,
          }}
          loading={createTrait.isPending}
          loaderProps={{ color: '#fff' }}
          maxSize={window.g_.MAX_UPLOAD_SIZE}
          onDrop={handleOnDrop}
          validator={(item) => {
            const type = item?.type;
            // Hacky way to show it's accepting folders
            return type === '' || IMAGE_MIME_TYPE.includes(type) ? null : true;
          }}
          openRef={dropboxOpenRef}
        >
          <Group className={classes.dropzoneOverlay}>
            {traits.length > 0 ? null :
              <Dropzone.Idle>
                <Group gap="xs" justify="center" px="1rem">
                  <Button
                    className="g-pE"
                    leftSection={<IconUpload size={20} />}
                    onClick={() => dropboxOpenRef.current?.()}
                    size="sm"
                    variant="white"
                  >
                    Select
                  </Button>
                  <Text ta="center"> or drop images/folders to add traits to this layer</Text>
                </Group>
              </Dropzone.Idle>
            }
            <Dropzone.Accept>
              <Group gap="xs">
                <IconUpload size={36} />
                <Text>Drop images here</Text>
              </Group>
            </Dropzone.Accept>
            <Dropzone.Reject>
              <Group gap="xs">
                <IconX size={36} />
                <Text>Unsupported file types</Text>
              </Group>
            </Dropzone.Reject>
          </Group>

          <Group justify="space-between" wrap="nowrap">
            <Group gap={5}>
              <Badge size="lg">{idx + 1}</Badge>
              <Tooltip label="Hide layer">
                <ActionIcon
                  aria-label="Hide layer"
                  className="g-pE"
                  color="#eff8ff"
                  disabled={updateLayer.isPending}
                  onClick={() => handleHideBtn(layer)}
                  variant="subtle"
                >
                  {layer.hidden ? <IconEyeOff size={22} /> : <IconEye size={22} />}
                </ActionIcon>
              </Tooltip>
            </Group>
            <LayerName layer={layer} updateLayer={updateLayer} />
            <Tooltip label="Move layer up">
              <ActionIcon
                className="g-pE"
                onClick={() => moveUp(layer.id, idx)}
                variant="white"
                disabled={idx === 0 || isPending}
                aria-label="Move layer up"
              >
                <IconArrowUp size={12} />
              </ActionIcon>
            </Tooltip>
          </Group>
          <LayerTraits
            layerId={layer.id}
            methods={{ pauseEmbla, resumeEmbla, updateTraitStateAndQueryCache }}
            setEmbla={setEmbla}
            traits={traits}
          />
          <Group justify="space-between" wrap="nowrap">
            <Button.Group>
              <Button
                className="g-pE"
                leftSection={<IconUpload size={14} />}
                onClick={() => dropboxOpenRef.current?.()}
                size="xs"
                variant="gradient"
              >
                Select or drop files
              </Button>
              <Tooltip label="Delete layer">
                <Button
                  aria-label="Delete layer"
                  className="g-pE"
                  disabled={isPending}
                  onClick={() => handleDeleteLayerBtn(layer.id)}
                  size="xs"
                  variant="light"
                >
                  <IconTrash size={14} />
                </Button>
              </Tooltip>
            </Button.Group>
            <Tooltip label="Move layer down">
              <ActionIcon
                className="g-pE"
                onClick={() => moveDown(layer.id, idx)}
                variant="white"
                disabled={endIdx === idx || isPending}
                aria-label="Move layer down"
              >
                <IconArrowDown size={12} />
              </ActionIcon>
            </Tooltip>
          </Group>
        </Dropzone>
      </Paper>
      <Button
        w="30%" miw={180} maw={250}
        onClick={() => addAfter(idx)}
        leftSection={<IconCirclePlus size={14} />}
        disabled={isPending}
      >
        Add Layer Here
      </Button>
    </Stack>
  );
}
