import { createContext, useContext, useState } from 'react';
import { useDisclosure } from '@mantine/hooks';

import Dialog from '../components/Dialog';


export const DialogContext = createContext(null);

export function DialogProvider({ children }) {
  const disclosure = useDisclosure(false);
  const [dialogProps, setDialogProps] = useState({});
  const [loading, setLoading] = useState(false);

  return (
    <DialogContext.Provider value={{ disclosure, setDialogProps, setLoading }}>
      {children}
      <Dialog disclosure={disclosure} loading={loading} {...dialogProps} />
    </DialogContext.Provider>
  );
}

export function useDialog(props) {
  const { disclosure, setDialogProps, setLoading } = useContext(DialogContext);

  const stopLoading = () => setLoading(false);

  function open(cb, args = []) {
    return function openModal() {
      const actionFn = () => {
        setLoading(true);
        cb(...args);
      };
      // Open as loading stopped
      setLoading(false);
      setDialogProps({ ...props, ...{ actionFn } });
      disclosure[1].open();
    };
  }

  function close() {
    disclosure[1].close();
    // Close without stopping loading (to prevent action button become clickable before modal is closed)
  }

  const [opened, { toggle }] = disclosure;
  return [opened, { open, close, stopLoading, toggle }];
}
