import { useEffect, useState } from 'react'
import { Anchor, Box, Group, Image, Paper, Skeleton, Tooltip } from '@mantine/core';
import { useParams } from 'react-router-dom';
import { IconAlertTriangle, IconInfoCircle } from '@tabler/icons-react';

import classes from './ArtPreview.module.css';
import { useTemplateConfig } from '../hooks/useConfigStore.mjs';
import mergeImages from '../helpers/mergeImages.mjs';


export default function ArtPreview({ traits, withinPortal = false }) {
  const [previewSrc, setPreviewSrc] = useState('');
  const [ratioWarning, setRatioWarning] = useState(false);

  const { templateId } = useParams();
  const [{ width, height }] = useTemplateConfig(templateId, ({ artWidth, artHeight }) => ({ width: artWidth, height: artHeight }));

  useEffect(() => {
    const mergeOptions = {
      width,
      height,
      // crossOrigin: 'anonymous', // todo: note for CDN usage
    };
    mergeImages(traits.map(trait => trait.asset_url), mergeOptions)
      .then(({ hasDifferentRatio, url }) => {
        setPreviewSrc(url);
        setRatioWarning(hasDifferentRatio);
      }).catch((err) => {
        console.log(err); // note: log to sentry
        setPreviewSrc('/preview-render-error.png');
      });
  }, [width, height]);

  const traitsSelected = <div>
    <Box ta="center" fw="bold">Traits Selected</Box>
    {traits.map(trait => <div key={trait.id}><strong>{trait.layerName}: </strong> {trait.name} ({trait.rarity}%)</div>)}
  </div>;

  return (
    <Paper withBorder={true} className={classes.preview}>
      <Group p="2px 5px" justify="flex-end" gap="xs">
          {ratioWarning &&
            <Tooltip label="A trait image has a different aspect ratio in this composite." withinPortal={withinPortal}>
              <IconAlertTriangle size={16} color="#d9d02f" />
            </Tooltip>
          }
          <Tooltip events={{ touch: true }} label={traitsSelected} withinPortal={withinPortal}>
            <IconInfoCircle size={16} />
          </Tooltip>
      </Group>
      <Tooltip.Floating label={traitsSelected} withinPortal={withinPortal}>
        <div className={classes.previewImageFrame}>
          {previewSrc ?
            <Anchor href={previewSrc} target="_blank"><Image src={previewSrc} className={classes.previewImage}/></Anchor> :
            <Skeleton className={classes.previewSkeleton} />
          }
        </div>
      </Tooltip.Floating>
    </Paper>
  );
}
