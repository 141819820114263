import { redirect } from 'react-router-dom';

import { rapini } from '../api/client.mjs';


export default async function loader({ params }) {
    let { collectionId } = params;
    const collection = await rapini.requests.getCollectionWithTemplates(collectionId);

    if (collection.status === window.g_.COLLECTION_STATUSES.DRAFT) {
        return redirect(`/collection/${collectionId}/edit`);
    }

    return { collection };
}
