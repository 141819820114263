import { ActionIcon, Group, Tooltip } from '@mantine/core';
import { IconEye, IconEyeOff, IconGripVertical, IconTrash } from '@tabler/icons-react';

import classes from './LayersOverviewItem.module.css';
import { rapini } from '../api/client.mjs';
import { notify } from '../helpers/index.mjs';
import LayerName from './LayerName';


export default function LayersOverviewItem({ handleDeleteLayerBtn, layer, provided, setTemplateLayerQueryData }) {
  const updateLayer = rapini.mutations.useUpdateLayer(layer.id, {
    onSuccess: ({ success }, variables) => {
      if (!success) {
        return notify({ message: 'Layer could not be updated.' });
      }
      setTemplateLayerQueryData({ ...layer, ...variables });
    },
  });

  function handleHideBtn({ hidden }) {
    updateLayer.mutate({ hidden: Number(!hidden) });
  }

  return (
    <Group
      className={classes.container}
      ref={provided.innerRef}
      {...provided.draggableProps}
    >
      <Tooltip label="Drag and reorder">
        <div {...provided.dragHandleProps} className={classes.dragHandle}>
          <IconGripVertical style={{ minWidth: 20, cursor: "grab" }} size={20} />
        </div>
      </Tooltip>
      <LayerName
        actionProps={{ variant: "transparent" }}
        inline={false}
        layer={layer}
        textProps={{ className: classes.layerName }}
        updateLayer={updateLayer}
      />
      <Group gap={4} wrap="nowrap">
        <Tooltip label="Hide layer">
          <ActionIcon
            aria-label="Hide layer"
            className={classes.actionIcon}
            disabled={updateLayer.isPending}
            onClick={() => handleHideBtn(layer)}
            variant="transparent"
          >
            {layer.hidden ? <IconEyeOff size={16} /> : <IconEye size={16} />}
          </ActionIcon>
        </Tooltip>
        <Tooltip label="Delete layer">
          <ActionIcon
            aria-label="Delete layer"
            onClick={() => handleDeleteLayerBtn(layer.id)}
            variant="transparent"
          >
            <IconTrash size={16} />
          </ActionIcon>
        </Tooltip>
      </Group>
    </Group>
  );
}
