import { Container } from '@mantine/core';

import classes from './CallToAction.module.css';
import ConnectButton from './ConnectButton';


export default function CallToAction(props) {
  return (
    <Container className={classes.container} fluid>
      <ConnectButton
        variant="gradient"
        gradient={{from: 'pink', to: 'yellow'}}
        size="xl"
        className={classes.connectButton}
        mt={40}
        {...props}
      />
    </Container>
  )
}
