import { Helmet } from 'react-helmet-async';

import { SITE_TITLE } from '../constants.mjs';
import Hero from '../components/Hero';
import Features from '../components/Features';
import CallToAction from '../components/CallToAction';


export default function Home() {
    return (
        <>
          <Helmet>
            <title>{SITE_TITLE}</title>
          </Helmet>
          <Hero/>
          <Features/>
          <CallToAction/>
        </>
    )
}
