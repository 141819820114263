import { QueryClient, QueryClientProvider as _QueryClientProvider } from '@tanstack/react-query';


export const queryClient = new QueryClient();

export default function QueryClientProvider({ children }) {
  return (
    <_QueryClientProvider client={queryClient}>
      {children}
    </_QueryClientProvider>
  );
}
