import { ActionIcon, Flex, Tooltip } from '@mantine/core';
import { getHotkeyHandler } from '@mantine/hooks';
import { IconCheck, IconX } from '@tabler/icons-react';


export default function PopoverInput({
  actionProps,
  cancelProps,
  close,
  handleChange,
  handleSaveBtn,
  inputProps,
  InputElement,
  label,
  loading,
  value
}) {
  return (
    <Flex justify="center" align="center">
      <InputElement
        autoFocus
        className="g-pE"
        onChange={handleChange}
        onKeyDown={getHotkeyHandler([
          ['Enter', handleSaveBtn],
          ['Escape', close],
        ])}
        display="inline-block"
        value={value}
        {...inputProps}
      />
      <ActionIcon.Group>
        <Tooltip label={label}>
          <ActionIcon
            aria-label={label}
            className="g-pE"
            loading={loading}
            onClick={handleSaveBtn}
            size="md"
            variant="gradient"
            {...actionProps}
          >
            <IconCheck size={16} />
          </ActionIcon>
        </Tooltip>
        <Tooltip label="Cancel">
          <ActionIcon
            aria-label="Cancel"
            className="g-pE"
            loading={loading}
            onClick={close}
            size="md"
            variant="light"
            {...cancelProps}
          >
            <IconX size={16} />
          </ActionIcon>
        </Tooltip>
      </ActionIcon.Group>
    </Flex>
  );
}
