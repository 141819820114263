import { Container, Image } from '@mantine/core';


export default function CreateLayerArrow(props) {
  return (
    <Container {...props} style={{ whiteSpace: "nowrap" }}>
      <Image src="/add-a-layer-to-add-assets.svg" display="inline" w="45vw" miw={170} maw={440} />
      <Image src="/arrow-up.png" display="inline" style={{ marginLeft: 10 }} w="6vw" miw={20} maw={70} />
    </Container>
  );
}
