import { DragDropContext } from '@hello-pangea/dnd';
import { Button, Container } from '@mantine/core';
import { useParams } from 'react-router-dom';
import { IconCirclePlus } from '@tabler/icons-react';
import { useQueryClient } from '@tanstack/react-query';

import { rapini } from '../api/client.mjs';
import { notify } from '../helpers';
import useDeleteLayer from '../hooks/useDeleteLayer.mjs';
import useSetLayerOrder from '../hooks/useSetLayerOrder.mjs';
import useSetTemplateLayerQueryData from '../hooks/useSetTemplateLayerQueryData.mjs';
import CreateLayerArrow from './CreateLayerArrow';
import Layer from './Layer';
import PageSkeleton from './PageSkeleton';


export default function CollectionLayers() {
  const { templateId: selectedTemplateId } = useParams();
  const { data: layerData = [], isLoading, isError } = rapini.queries.useGetTemplateLayers(selectedTemplateId);
  const setTemplateLayerQueryData = useSetTemplateLayerQueryData(layerData, selectedTemplateId);
  const layerOrder = useSetLayerOrder(selectedTemplateId, layerData);
  const handleDeleteLayerBtn = useDeleteLayer(layerOrder);
  const queryClient = useQueryClient();
  const layerInnerMethods = {};

  const createLayer = rapini.mutations.useCreateLayer({
    onSuccess: ({ id }) => {
      if (!id) {
        return notify({ message: 'A new layer could not be created.' });
      }
    }
  });

  function addAsFirst() {
    addLayer(0);
  }

  function addAfter(index) {
    addLayer(index + 1);
  }

  function addLayer(index) {
    createLayer.mutateAsync({ name: 'Layer', template_id: selectedTemplateId })
      .then(({ id }) => {
        layerOrder.add(id, index);
      });
  }

  function moveDown(layerId, index) {
    const newIndex = index + 1;
    if (newIndex >= layerData.length) {
      return;
    }
    layerOrder.move(layerId, newIndex);
  }

  function moveUp(layerId, index) {
    const newIndex = index - 1;
    if (newIndex < 0) {
      return;
    }
    layerOrder.move(layerId, newIndex);
  }

  function handleOnBeforeCapture({ draggableId }) {
    const [sourceLayerId] = draggableId.split('_');
    layerInnerMethods[sourceLayerId].pauseEmbla();
  }

  function handleOnDragEnd(dropResult) {
    layerInnerMethods[dropResult.source.droppableId].resumeEmbla();
    if (!dropResult.destination) {
      return;
    }

    const {
      destination: { droppableId: targetLayerId },
      draggableId,
      source: { droppableId: sourceLayerId }
    } = dropResult;
    const [, traitId] = draggableId.split('_');

    if (targetLayerId === sourceLayerId) {
      return;
    }

    const layers = JSON.parse(JSON.stringify(layerData));
    const sourceLayer = layers.find(layer => `${layer.id}` === sourceLayerId);
    const targetLayer = layers.find(layer => `${layer.id}` === targetLayerId);

    const traitSourceIndex = sourceLayer.traits.findIndex(trait => `${trait.id}` === traitId);
    const trait = sourceLayer.traits[traitSourceIndex];

    sourceLayer.traits.splice(traitSourceIndex, 1);
    // Add to end
    targetLayer.traits.push(trait);

    layerInnerMethods[sourceLayerId].setTraits(sourceLayer.traits);
    layerInnerMethods[targetLayerId].setTraits(targetLayer.traits);

    rapini.requests.updateTrait({ layer_id: targetLayerId }, traitId)
      .then(({ success }) => {
        queryClient.setQueryData(
          rapini.queryKeys.getTemplateLayers(selectedTemplateId),
          layers
        );
      }).catch((err) => {
        notify({ message: err.message, title: 'Failed to update the trait\'s position' });
        // Revert in case of an error
        layerInnerMethods[sourceLayerId].setTraits([...targetLayer.traits]);
        layerInnerMethods[targetLayerId].setTraits([...sourceLayer.traits]);
      });
  }

  const isPending = createLayer.isPending || layerOrder.isPending;

  const layers = layerData?.map((layer, idx) =>
    <Layer
      endIdx={layerData.length - 1}
      idx={idx}
      key={layer.id}
      layer={layer}
      layerInnerMethods={layerInnerMethods}
      methods={{
        addAfter,
        handleDeleteLayerBtn,
        isPending,
        moveDown,
        moveUp,
        setTemplateLayerQueryData,
      }}
    />
  );

  // todo: replace PageSkeleton here
  return (
    <Container mt="2rem" p={0}>
      {isLoading && <PageSkeleton/>}
      {!isLoading && !isError && <>
        <Button
          w="30%" miw={180} maw={250} display="block" m="1rem auto"
          onClick={addAsFirst}
          leftSection={<IconCirclePlus size={14} />}
          disabled={isPending}
        >
          Add Layer Here
        </Button>
        {layers?.length ?
          <DragDropContext
            onBeforeCapture={handleOnBeforeCapture}
            onDragEnd={handleOnDragEnd}
          >
            {layers}
          </DragDropContext> :
          <CreateLayerArrow/>
        }
      </>}
    </Container>
  );
}
