import { useState } from 'react';
import { Grid, Transition } from '@mantine/core';

import classes from './Panes.module.css';
import { useGeneralConfig } from '../hooks/useConfigStore.mjs';
import ArtPreviewPane from './ArtPreviewPane';
import LayersOverview from './LayersOverview';


export default function Panes() {
  const [generalConfig] = useGeneralConfig();
  const [panesMounted, setPanesMounted] = useState({
    artPreview: generalConfig.artPreview,
    layersOverview: generalConfig.layersOverview,
  });

  return (
    <Grid className={classes.panesCon} mt="1.5rem">
      <Transition
        mounted={generalConfig.artPreview}
        transition="fade"
        duration={400}
        onEnter={() => setPanesMounted({ ...panesMounted, ...{ artPreview: true } })}
        onExited={() => setPanesMounted({ ...panesMounted, ...{ artPreview: false } })}
        timingFunction="ease"
      >
        {(styles) =>
          <Grid.Col style={styles} span={{ base: 12, xs: panesMounted.layersOverview === false ? (generalConfig.layersOverview ? 6 : 12) : 6 }}>
            <ArtPreviewPane/>
          </Grid.Col>
        }
      </Transition>
      <Transition
        mounted={generalConfig.layersOverview}
        transition="fade"
        duration={400}
        onEnter={() => setPanesMounted({ ...panesMounted, ...{ layersOverview: true } })}
        onExited={() => setPanesMounted({ ...panesMounted, ...{ layersOverview: false } })}
        timingFunction="ease"
      >
        {(styles) =>
          <Grid.Col style={styles} span={{ base: 12, xs: panesMounted.artPreview === false ? (generalConfig.artPreview ? 6 : 12) : 6 }}>
            <LayersOverview/>
          </Grid.Col>
        }
      </Transition>
    </Grid>
  );
}
