import {
  Badge,
  Group,
  Title,
  Text,
  Card,
  SimpleGrid,
  Container,
  rem,
  useMantineTheme,
} from '@mantine/core';
import { IconUniverse, IconHammer, IconUpload } from '@tabler/icons-react';

import classes from './Features.module.css';


const featuresData = [
  {
    title: 'Upload',
    description:
      'Upload assets for your NFTs',
    icon: IconUpload,
  },
  {
    title: 'Generate',
    description:
      'Easily generate collections, manage their metadata',
    icon: IconUniverse,
  },
  {
    title: 'Mint',
    description:
      'Mint collections, easily create different editions',
    icon: IconHammer,
  },
];

export default function Features() {
  const theme = useMantineTheme();
  const features = featuresData.map((feature) => (
    <Card key={feature.title} shadow="md" radius="md" className={classes.card} padding="xl">
      <feature.icon
        style={{width: rem(50), height: rem(50)}}
        stroke={2}
        color={theme.colors.blue[6]}
      />
      <Text fz="lg" fw={500} className={classes.cardTitle} mt="md">
        {feature.title}
      </Text>
      <Text fz="sm" c="dimmed" mt="sm">
        {feature.description}
      </Text>
    </Card>
  ));

  return (
    <div className={classes.features}>
      <Container size="lg" py="xl">
        <Group justify="center">
          <Badge variant="filled" size="lg">
            Features
          </Badge>
        </Group>

        <Title order={2} className={classes.title} ta="center" mt="sm">
          Drag and Drop Tools
        </Title>

        <Text c="dimmed" className={classes.description} ta="center" mt="md">
          Create and manage NFT collections with ease using our drag and drop tools.
        </Text>

        <SimpleGrid cols={{base: 1, md: 3}} spacing="xl" mt={50}>
          {features}
        </SimpleGrid>
      </Container>
    </div>
  );
}
